import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContentAllExceptIndex from '../components/content-all-except-index';
import ContentSnd from '../components/content-snd';
import Segment from '../images/cortex-global-segment.svg';
import Footer from '../components/footer';

const PrivacyPolicy = () => {
	const fontSize = { fontSize: 30 };
	return (
		<Layout>
			<SEO title='Cortex - Privacy Policy' />
			<ContentAllExceptIndex title='privacy'>
				<div className='cortex-home-content-nd'>Privacy Policy</div>
			</ContentAllExceptIndex>
			<ContentSnd>
				<div className='cortex-privacy-content'>
					<div className='cortex-privacy-content-container'>
						<img src={Segment} alt='ctcSegment' />
						<div className='cortex-privacy-content-title'>
							DATA PRIVACY NOTICE
						</div>
						<p className='cortex-privacy-content-p'>
							Cortex Technologies Corporation (collectively referred to as
							“CTC”) values the privacy of its Customers. We take the
							protection and security of our Customers’ Personal Data with
							utmost importance and commitment. This Data Privacy Notice
							contains a summary of our policies regarding the collection,
							use, storage, disclosure, and disposal of our Customers’
							Personal Data which we receive and collect from our Customers
							through our authorized representatives, contact center
							channels, official websites, web-based and mobile-based
							applications, and other forms of communications.{' '}
						</p>
						<div className='cortex-privacy-content-title' style={fontSize}>
							What Personal Data do we collect?
						</div>
						<p className='cortex-privacy-content-p'>
							We collect and process our Customers’ Personal Data only upon
							their affirmative consent and in accordance with the Data
							Privacy Act. In the usual course of our business activities,
							we collect the following Personal Data from our Customers:
						</p>
						<div className='cortex-privacy-content-p-sub-container'>
							<p className='cortex-privacy-content-p-sub'>1) full name </p>
							<p className='cortex-privacy-content-p-sub'>2) age  </p>
							<p className='cortex-privacy-content-p-sub'>3) gender </p>
							<p className='cortex-privacy-content-p-sub'>
								4) home address {' '}
							</p>
							<p className='cortex-privacy-content-p-sub'>
								5) email address {' '}
							</p>
							<p className='cortex-privacy-content-p-sub'>
								6) contact numbers{' '}
							</p>
						</div>
						<p className='cortex-privacy-content-p'>
							When our Customers provide us with Personal Data by which they
							can be reasonably identified, they can be assured that their
							Personal Data will be used only in accordance with our Privacy
							Policy and the relevant Terms and Conditions governing their
							relationship with us. We collect Customers’ Personal Data from
							the following sources: (1) Personal Information Sheet; (2)
							information we collect about Customers when they contact us
							through our authorized representatives and contact centers,
							email, mobile applications or services, and other online and
							mobile platforms; and (3) information we collect about our
							Customers from public records and from other available sources
							authorized to disclose such information.
						</p>
						<div className='cortex-privacy-content-title' style={fontSize}>
							How do we use your Personal Data?
						</div>
						<p className='cortex-privacy-content-p'>
							All our Customers’ Personal Data, which are collected with
							their consent, and other information with regard to their
							transactions with us may be combined and processed: (1) to
							improve our products and services, (2) for communicating with
							our Customers, and (3) for sales, marketing, aftermarket, and
							research and development purposes. We may also disclose our
							Customers’ Personal Data to: (1) our third party service
							providers that perform business operations on our behalf; (2)
							partners who collaborate with us to provide services to our
							Customers; or (3) business partners that provide joint
							communications that we hope our Customers find of interest
							through processing of the following: (a) our Customers’
							Internet Protocol address; (b) search terms used; (c) pages
							and internal links accessed on our site; (d) date and time of
							visit of the site; (e) geolocation; (f) referring site or
							platform (if any) through which our Customers clicked through
							to this site; (g) operating system; (h) web browser type; or
							(i) type of device used.
						</p>
						<div className='cortex-privacy-content-title' style={fontSize}>
							How do we protect your Personal Data?
						</div>
						<p className='cortex-privacy-content-p'>
							We protect our Customers’ Personal Data through
							organizational, procedural, and technical security measures.
							We ensure that only authorized employees and third party
							service providers, who have undertaken to satisfy our
							information security and data privacy requirements are allowed
							to process our Customers’ Personal Data. While we warrant that
							due diligence is exercised to guarantee privacy of our
							Customers’ Personal Data, note that no data transmission
							conducted over the internet and/or through electronic channels
							can be guaranteed to be absolutely secure. Your Personal Data
							shall be stored in our secure database for ten (10) years.
							Thereafter, physical and electronic records shall be disposed.
						</p>
						<div className='cortex-privacy-content-title' style={fontSize}>
							Contact Us
						</div>
						<p
							className='cortex-privacy-content-p'
							style={{ paddingBottom: 100 }}
						>
							If you have any comments or questions or if you wish to
							access, correct, update or ask for a copy of personal
							information we hold about you, or if you have questions about
							CTC’s privacy practices in general, please email &nbsp;
							<a href='mailto:dpo@cortex.ph' target='_top'>
								dpo@cortex.ph
							</a>
						</p>
					</div>
				</div>
			</ContentSnd>
			<Footer />
		</Layout>
	);
};

export default PrivacyPolicy;
